// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap"
import "./controllers"


//
//
// MOBILE MENUE
document.addEventListener('turbo:load', () => {
  const dropdownLinks = document.querySelectorAll('#navbar_top ul li.dropdown > a');

  dropdownLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      if (window.innerWidth <= 991.98) {
        const nextUl = link.nextElementSibling;

        if (nextUl.style.display === 'none' || !nextUl.style.display) {
          document.querySelectorAll('#navbar_top ul li.dropdown ul').forEach((ul) => {
            ul.style.display = 'none';
          });

          nextUl.style.display = 'block';
        } else {
          nextUl.style.display = 'none';
        }

        e.preventDefault();
      }
    });
  });
});
