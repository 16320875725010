import { Controller } from "@hotwired/stimulus"

//
//== FANCYBOX
import { Fancybox } from "@fancyapps/ui";

export default class extends Controller {
  connect () {
    Fancybox.bind("[data-fancybox]", {
      Toolbar: {
        display: {
          middle: [
            "zoomIn",
            "zoomOut",
            "toggle1to1",
          ],
          right: ["slideshow", "close"],
        },
      }
    })
  }

  disconnect () {
    Fancybox.destroy();
  }
}
